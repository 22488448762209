import axios from '@/libs/axios'

class DataService {
    getAll() {
        return axios.get("/chooseFactore");
    }
    update(id, data) {
        return axios.put(`/chooseFactore/${id}`, data);
    }
}
export default new DataService();
<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="initValues"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{headerType}} {{ pageTitle }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="brnadForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="initValues"
        >

          <!-- Location  -->
          <validation-provider
            #default="validationContext"
            name="Location"
            rules="required|integer"
          >
            <b-form-group
              label="Location"
              label-for="Location"
            >
              <b-form-input
                id="location"
                v-model="p_location"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Location"
              />
      
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.p_location">{{ allerrors.p_location[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- availabilit in stock -->
          <validation-provider
            #default="validationContext"
            name="Availability stock"
            rules="required|integer"
          >
            <b-form-group
              label="Availability stock"
              label-for="p_availability_stock"
            >
              <b-form-input
                id="p_availability_stock"
                v-model="p_availability_stock"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Availability stock"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.p_availability_stock">{{ allerrors.p_availability_stock[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- workshop status -->
          <validation-provider
            #default="validationContext"
            name="p_workshop_status"
            rules="required|integer"
          >
            <b-form-group
              label="Workshop Status"
              label-for="p_workshop_status"
            >
              <b-form-input
                id="p_workshop_status"
                v-model="p_workshop_status"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Workshop status"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.p_workshop_status">{{ allerrors.p_workshop_status[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- max request -->
          <validation-provider
            #default="validationContext"
            name="Max Request"
            rules="required|integer"
          >
            <b-form-group
              label="Max Request"
              label-for="p_max_request"
            >
              <b-form-input
                id="p_max_request"
                v-model="p_max_request"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter max Request"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.p_max_request">{{ allerrors.p_max_request[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- rate customer -->
          <validation-provider
            #default="validationContext"
            name="Availability stock"
            rules="required|integer"
          >
            <b-form-group
              label="rate customer"
              label-for="p_rate_customer"
            >
              <b-form-input
                id="p_rate_customer"
                v-model="p_rate_customer"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Rate customer"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.p_rate_customer">{{ allerrors.p_rate_customer[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- max_workshop_order_day -->
          <validation-provider
            #default="validationContext"
            name="Availability stock"
            rules="required|integer"
          >
            <b-form-group
              label="Max workshop order by day"
              label-for="max_workshop_order_day"
            >
              <b-form-input
                id="max_workshop_order_day"
                v-model="max_workshop_order_day"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Max workshop order by day"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.max_workshop_order_day">{{ allerrors.max_workshop_order_day[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- max_distance_search -->
          <validation-provider
            #default="validationContext"
            name="Availability stock"
            rules="required|integer"
          >
            <b-form-group
              label="max distance search"
              label-for="max_distance_search"
            >
              <b-form-input
                id="max_distance_search"
                v-model="max_distance_search"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Max distance search"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.max_distance_search">{{ allerrors.max_distance_search[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- next_request_after_minutes -->
          <validation-provider
            #default="validationContext"
            name="Next request after minutes"
            rules="required|integer"
          >
            <b-form-group
              label="Next request after minutes"
              label-for="next_request_after_minutes"
            >
              <b-form-input
                id="next_request_after_minutes"
                v-model="next_request_after_minutes"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Availability stock"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.next_request_after_minutes">{{ allerrors.next_request_after_minutes[0] }}</span>
            </b-form-group>
          </validation-provider>

          <!-- availabilit in stock -->
          <validation-provider
            #default="validationContext"
            name="Sleep per second"
            rules="required|integer"
          >
            <b-form-group
              label="Sleep per second"
              label-for="sleep_per_second"
            >
              <b-form-input
                id="sleep_per_second"
                v-model="sleep_per_second"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter Availability stock"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.sleep_per_second">{{ allerrors.sleep_per_second[0] }}</span>
            </b-form-group>
          </validation-provider>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!loading"
            >
              {{ headerType }} 
            </b-button>
            <!-- spinner -->
            <b-button
              v-if="loading"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
            <!-- end spinner -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BCardText, BMedia, BAvatar, BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import DataService from './data/services'
export default {
  
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BSpinner,
    BRow, 
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    useInputImageRenderer,
    avatarText,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataSilder: {
      type: Object,
      default: () => {},
    },
    pageTitle: {
      type: String,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      p_location: '',
      p_availability_stock:'',
      p_workshop_status: '',
      p_max_request:'',
      p_rate_customer:'',
      max_workshop_order_day:'',
      max_distance_search:'',
      next_request_after_minutes:'',
      sleep_per_second:'',
     
      
      loading: false,
      headerType: 'Add',
      allerrors: [],
      checkMachineType: '',
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.dataSilder).length === 0) {
        this.headerType = 'Add'
        this.initValues()
      }else {    
        this.id       = this.dataSilder.id
        this.p_location     = this.dataSilder.p_location
        this.p_availability_stock  = this.dataSilder.p_availability_stock
        this.p_workshop_status = this.dataSilder.p_workshop_status
        this.p_max_request     = this.dataSilder.p_max_request
        this.p_rate_customer = this.dataSilder.p_rate_customer
        this.max_workshop_order_day = this.dataSilder.max_workshop_order_day
        this.max_distance_search = this.dataSilder.max_distance_search
        this.next_request_after_minutes = this.dataSilder.next_request_after_minutes
        this.sleep_per_second = this.dataSilder.sleep_per_second
        this.headerType = 'Edit'
        // this.initValues()
      }
    },

  },
  methods: {
    initValues() {
      if(this.dataSilder.id) return
        this.id = null
        this.$store.state.admin.machines = [{  machine_size_id: '', number_liter: ''}]
        this.allerrors = {}
    },

 
    onSubmit(){
      this.editData();
    },
    prepareData(){
      return {
        p_location : this.p_location,
        p_availability_stock : this.p_availability_stock,
        p_workshop_status : this.p_workshop_status,
        p_max_request : this.p_max_request,
        p_rate_customer : this.p_rate_customer,
        max_workshop_order_day: this.max_workshop_order_day,
        max_distance_search: this.max_distance_search,
        next_request_after_minutes: this.next_request_after_minutes,
        sleep_per_second: this.sleep_per_second
      }
    },
    editData(){
      this.loading = true;
        DataService.update(this.id, this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$emit('refreshData',response.data.data)          
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    getMachineSizes(){
        DataService.mahineSize()
        .then(response => {
          this.machineSizes = response.data.data
        })
    },
  },

  setup(props, { emit }) {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      console.log('amro');
      console.log(base64);
      console.log(props);
      props.categories_image_img = base64
    })


    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      // userData,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
